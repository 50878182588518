import PropTypes from "prop-types"
import React from "react"
import {
	HeaderWrapper,
	HeadingWrapper, MenuItemWrapper, NavbarBrand, NavbarMenu, StyledLink,
} from "./header.style"
import styled from 'styled-components';

import {slide as Menu} from 'react-burger-menu';

const pages = [
	{
		name: "Home",
		path: "/",
	},
	{
		name: "About Us",
		path: "/about-us/",
	},
	{
		name: "SDGs",
		path: "/SDGs/"
	},
	{
		name: "UI GreenMetric",
		path: "/ui-green-metric/",
	},
	{
		name: "Contact Us",
		path: "/contact/",
	},
];

const HamburgerWrapper = styled.div`
	position: relative;
`;

const Hamburger = styled(Menu)`
	background-color: #373a47;
`;

const Header = ({siteTitle}) => {
	return (
		<HeaderWrapper>
			<HeadingWrapper>
				<NavbarBrand>
					<StyledLink to="/">{siteTitle}</StyledLink>
				</NavbarBrand>
				<HamburgerWrapper className="hamburger">
					<Hamburger right>
						{pages.map(({name, path}) => (
							<StyledLink to={path} key={name}>
								{name}
							</StyledLink>
						))}
					</Hamburger>
				</HamburgerWrapper>

				<NavbarMenu className="normal">
					<MenuItemWrapper>
						{pages.map(({name, path}) => (
							<StyledLink to={path} key={name}>
								{name}
							</StyledLink>
						))}
					</MenuItemWrapper>
				</NavbarMenu>
			</HeadingWrapper>
		</HeaderWrapper>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
