import React from "react"
import { FooterWrapper, TextContainer } from "./footer.style"

const Footer = () => {
  return (
    <FooterWrapper>
      <TextContainer>
        <h3>Office of Strategy Management</h3>
        <h4>King Mongkut's Institute of Technology Ladkrabang</h4>
        <p>
          1 Soi Chalongkrung 1, Ladkrabang, Bangkok 10520, Thailand Tel.
          (662)329 8000
          <br />
          http://www.osm.kmitl.ac.th/
        </p>
      </TextContainer>
    </FooterWrapper>
  )
}

export default Footer
