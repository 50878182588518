/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import Header from "./header"
import "./layout.css"
import styled from "styled-components"
import Footer from "./footer";

const BodyWrapper = styled.div`
  margin:  auto;
  max-width: 960px;
  font-family: "Sarabun", sans-serif;
  min-height: 100vh;
  padding: 6rem 1.0875rem 1.45rem;
`;

const Layout = ({children}) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	return (
		<>
			<link
				href="https://fonts.googleapis.com/css?family=Sarabun&display=swap"
				rel="stylesheet"
				lazyload
			/>
			<link
				rel={"preconnect"}
				href={"https://firebasestorage.googleapis.com"}
			/>
			<Header siteTitle={data.site.siteMetadata.title}/>
			<BodyWrapper>
				<main>{children}</main>
			</BodyWrapper>
			<Footer/>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
