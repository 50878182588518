import styled from "styled-components"
import {Link} from "gatsby"

export const HeaderWrapper = styled.header`
  background-color: #e35205;
  display: flex;
  align-items: center;
  margin-bottom: 1.45rem;
  position: fixed;
  width: 100vw;
  .hamburger {display: none;}
  /* Position and sizing of burger button */
.bm-burger-button {
  width: 36px;
  height: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #4f4f4f;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  min-height: 100vh;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.5em 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.5rem 0;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
`

export const HeadingWrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 1.45rem 1.44rem;
  background-color: #e35205;
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 965px) {
  	justify-content: space-between;
  	.hamburger {
  		display: block;
  	}
  	.normal {
  		display: none;
  	}
  }
`

export const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`

export const NavbarBrand = styled.div`
  width: 40%;
  font-size: 1.5rem;
 
  @media only screen and (min-width: 300px) and (max-width:  700px) {
  	width: 80%;
  }
`

export const NavbarMenu = styled.div`
  width: 60%;
`

export const MenuItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
