import styled from "styled-components"

export const TextContainer = styled.div`
  width: 100%;
  max-width: 960px;
  padding: 1.45rem 1.0875rem;
  color: white;
`;

export const FooterWrapper = styled.footer`
  background-color: #4f4f4f;
  margin-top: 1.45rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
